.workBox {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 4rem;
  column-gap: 4rem;
  padding: 0 18rem 4rem;
  text-align: center;
  color: white;
  justify-content: center;
}

.workModule {
  display: inline-block;
  width: 40rem;
  margin: 0 auto;
}

.workBox img {
  height: 18rem;
  width: 32rem;
  border-radius: 8px;
}

.githubLink {
  color: var(--orange);
  text-decoration: none;
}

.githubLink:hover {
  color: gray;
}

.githubLink:active {
  color: black;
}

@media screen and (max-width: 1840px) {
  .workModule {
    width: 24rem;
  }

  .workBox img {
    height: 12rem;
    width: 24rem;
  }
}

@media screen and (max-width: 1366px) {
  .workBox {
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .workModule {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: unset;
    padding: 0;
    margin: 0;
  }

  .workBox p {
    width: unset;
  }

  .workBox img {
    width: 90vw;
    height: 51vw;
  }
}
