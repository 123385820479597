:root {
  --bg: rgb(58, 58, 58);
  --orange: rgb(222, 95, 40);
}

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

body {
  background-color: var(--bg);
}

figcaption {
  color: var(--orange);
}

ul.contactLinks {
  width: 100%;
  padding: 0 16rem;
}

.header {
  position: relative;
  text-align: center;
}

.banner {
  width: 100%;
  height: 10vh;
  object-fit: cover;
}

.bannerText {
  color: rgb(255, 255, 255);
  font-size: 3.3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 900;
}

.profileImage {
  height: 20rem;
  width: 20rem;
  margin-right: 2rem;
  border: 1px solid var(--orange);
  border-radius: 8px;
}

.contactSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contactLinks {
  color: white;
  font-size: 2.5rem;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
}

.contactLink {
  text-decoration: none;
  color: white;
}

.contactLink:hover {
  color: var(--orange);
}

.contactLink:active {
  color: black;
}

.footer {
  color: var(--orange);
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: rgb(43, 43, 43);
  height: 56px;
  font-size: 3.2rem;
}

.footerIcons {
  list-style: none;
  justify-content: center;
  display: flex;
  column-gap: 30px;
  color: white;
}

/* Media queries for accessibility across screen sizes and resolutions */
@media screen and (max-width: 1366px) {
  * {
    scroll-margin: 90px;
  }

  nav {
    height: 80px;
  }

  header,
  main {
    text-align: center;
    font-size: 0.7em;
    flex-direction: column;
  }

  aside {
    font-size: 0.85rem;
    width: 20%;
  }

  .header {
    display: none;
  }

  .contact {
    height: 9rem;
  }

  .message {
    max-width: 80vw;
  }
}
