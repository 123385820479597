.sidebarHolder {
    height: 100%;
    width: 16rem;
    position: fixed;
    left: 0;
    bottom: 62px;
    top: 10vh;
}

.sidebar {
    background-color: rgb(43, 43, 43);
    height: 100%;
    box-shadow: 2px 0 6px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
}

.sidebarItems {
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
}

.sidebar input, .hamburgerHelper {
    display: none;
}

.sidebar:has(input:checked) {
    display: none;
}

.sidebarHolder:has(input:not(:checked)) >.hamburgerHelper {
    display: none;
}

.sidebarHolder a {
    text-decoration: none;
    color: white;
    font-size: 2rem;
    padding: 40px;
    width: 100%;
    transition: .3s;
    border-radius: 0 8px 8px 0;
}

.sidebarHolder a:hover {
    background-color: rgb(75, 75, 75);
}

.sidebar label {
    display: none;
}

.activeLink, .activeLink:hover {
    background-color: rgb(222, 95, 40)!important;
}

.title {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 900;
    position: absolute;
    right: 2rem;
    display: none;
  }

@media screen and (max-width: 1366px) {
    .sidebarHolder {
        width: 100vw;
        top: 0;
    }

    .sidebar label {
        display: block;
    }

    .hamburgerHelper, .closeHelper {
        width: 100vw;
        background-color: rgb(43, 43, 43);
        font-size: 3rem;
        display: block;
        background-image: url('../../images/banner.png');
        height: 3rem;
        color: white;
    }

    .hamburgerHelper {
        padding-left: 5px;
    }

    .sidebarHolder:has(input:checked) {
        z-index: 2;
    }

    .sidebar:has(input:checked) {
        display: flex;
    }
    
    .sidebarHolder:has(input:not(:checked)) >.hamburgerHelper {
        display: flex;
    }

    .sidebar:has(input:not(:checked)) {
        display: none !important;
    }
    
    .sidebarHolder:has(input:checked) >.hamburgerHelper {
        display: none !important;
    }

    .sidebarItems {
        overflow-y: scroll;
    }

    .title {
        display: block;
        top: 0.35rem;
    }
}