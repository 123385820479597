.aboutContent {
  color: white;
  margin-bottom: 2rem;
}

.aboutHolder {
    margin: auto;
    max-width: 60vw;
    display: flex;
}

.aboutText {
  margin: auto;
  font-size: 2rem;
}

.about {
  height: 24rem;
}

@media screen and (max-width: 1366px) {
  .aboutHolder {
    max-width: 100vw;
    flex-direction: column;
  }

  .aboutHolder img {
    margin-bottom: 2rem;
  }
}
