.form {
    font-size: 20px;
    text-align: center;
    box-sizing: border-box;
    width: 75%;
    margin: 0 auto 4rem;
  }
  
  .form p {
    font-size: 40px;
    color: white;
    margin: 0 0 2rem;
  }
  
  .form label, .formLabel {
    color:white;
    display: inline-block;
  }
  
  .formItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input, .message {
    width: 18rem;
    height: 36px;
    background-color: rgb(178, 178, 178);
    text-align: center;
    font-size: 20px;
    z-index: 1;
    border-radius: 8px;
  }
  
  .message {
    height: 100px;
    width: 32rem;
    padding: 4px;
    text-align: left;
  }
  
  .send {
    width: 6rem;
    font-size: 20px;
    background-color: var(--orange);
    border-radius: 8px;
    margin: 20px 20rem; 
  }
  
  .send:hover {
    background-color: rgb(232, 110, 57);
    cursor: pointer;
  }

  @media screen and (max-width: 480px) {
    .message {
      width: 21rem;
    }
  }